.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {

  bottom: -7px
}

.swiper-pagination-bullet {
  background: white;
}